// @flow
import { createGlobalStyle } from 'styled-components';
import {
  reset,
  fonts,
  components,
} from 'styles/index';
import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fonts}
  ${components}
  
  html, body {
    height: 100%;
  }
  
  img, svg {
    width: 100%;
  }

  .modal-header, .modal-body {
    padding: 30px;
  }

    @media only screen and (max-width: 414px) {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  p.size-18 {
    font-size: 18px;
  }
  
  button {
    background: transparent;
    border: none;
  }
  
  a:hover {
    text-decoration: none;
  }

  td, th {
    padding: 16px;
  }
  
  table {
    border: none !important;
    margin-bottom: 30px;
  }
  
  table strong {
    font-weight: 800;
  }
  
  tr:nth-child(2n) {
    background-color: #f4f4f4;
  }
`;

export default GlobalStyle;
