// @flow
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from 'view/Home';
import AboutUs from 'view/AboutUs';
import OurProducts from 'view/OurProducts';
import LegalInformation from 'view/LegalInformation';
import PrivacyStatement from 'view/PrivacyStatement';

export const CustomRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/our-products" element={<OurProducts />} />
    <Route path="/privacy-and-cookies-statement" element={<PrivacyStatement />} />
    <Route path="/legal-information" element={<LegalInformation />} />
  </Routes>
);
