import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import NavBar from 'components/NavBar';
import { WhatWeDoSection } from './StyledComponents';
import 'aos/dist/aos.css';

const PrivacyStatement = () => (
  <>
    <NavBar light />
    <WhatWeDoSection>
      <Grid>
        <Row middle="xs">
          <Col xs={12} md={9}>
            <div>
              <h2>Privacy and Cookies Statements</h2>
              <p>Beazley Plc (Beazley) values its customers and is committed to protecting and respecting your privacy;
                and the lawful and correct treatment of personal data. This Data Privacy Notice informs and explains how
                Beazley will process and protect any personal data we collect or receive about you. It applies to
                personal data provided by customers, brokers, coverholders, claimants, third party services providers
                (TPAs), complainants and job applicants. Please read this Data Privacy Notice carefully to understand
                our practices regarding personal data.
              </p>
              <p>Your personal data has either been, or will be collected by, or transferred to, Beazley. We can be
                contacted via post or by email at the below addresses. We aim to respond to all correspondence within
                thirty (30) days.
              </p>
              <p>The Data Protection Officer <br />
                Beazley Plc <br />
                22 Bishopsgate <br />
                London <br />
                EC2N 4BQ <br />
                <strong>Email:</strong><a href="mailto:DPO@beazley.com">DPO@beazley.com</a>
              </p>
              <p>The Beazley Data Protection Officer will handle any questions you may have on the use of your personal
                data and your rights as a data subject. This is covered in further detail under
                <em>Your Data Subject Rights</em>.
              </p>
              <h3>Types of personal data we hold</h3>
              <p>We capture and process a variety of different types of data, including personal data depending on the
                nature of the services involved. This includes but is not limited to:
              </p>
              <table
                cellPadding="0"
                cellSpacing="0"
                className="tableRow"
                dir="ltr"
                frame="box"
                rules="rows"
                style={{ border: '1px solid #050505' }}
              >
                <tbody>
                  <tr>
                    <td valign="top" width="236"><strong>Type of Personal Data</strong></td>
                    <td valign="top" width="484"><strong>Example</strong></td>
                  </tr>
                  <tr>
                    <td valign="top" width="236"><strong>Individual details</strong></td>
                    <td valign="top" width="484">Name, address, other contact details (e.g. email and telephone
                      numbers), gender, marital status, date and place of birth, nationality, health, national unique
                      identifier e.g. &nbsp;social security or Public Service number, passport number, driving licence
                      number, &nbsp;employer, job title and employment history, and family details, including their
                      relationship to you
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="236"><strong>Financial and credit information</strong></td>
                    <td valign="top" width="484">Bank account or payment card details, income or other financial
                      information, credit history, and credit score
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="236"><strong>Risk details</strong></td>
                    <td valign="top" width="484">Information about you which we need to collect in order to assess the
                      risk to be insured and provide a quote. This may include data relating to your health, criminal
                      convictions, or other special categories of personal data&nbsp;or the health of the people to be
                      covered.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="236"><strong>Policy information</strong></td>
                    <td valign="top" width="484">Information about the quotes you receive and policies you take out</td>
                  </tr>
                  <tr>
                    <td valign="top" width="236"><strong>Anti-fraud data</strong></td>
                    <td valign="top" width="484">Sanctions and criminal offences, and information received from various
                      anti-fraud databases relating to you or those seeking cover
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="236"><strong>Previous and current claims</strong></td>
                    <td valign="top" width="484">Information about previous and current claims, (including other
                      unrelated insurances), which may include data relating to your health, criminal convictions, or
                      other special categories of personal data and in some cases surveillance reports
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>Sometimes we may need to process special categories of personal data. These are certain types of
                personal data which require additional privacy protection. The special categories are:
              </p>
              <ul type="disc">
                <li>Racial or ethnic origin;</li>
                <li>Political opinions;</li>
                <li>Religious or philosophical beliefs;</li>
                <li>Trade union membership;</li>
                <li>Genetic or biometric data;</li>
                <li>Health data;</li>
                <li>Sex life or sexual orientation.</li>
                <li>Criminal convictions</li>
              </ul>
              <p>Personal data including special category data may be required to allow us to provide a quote,
                underwrite your policy, consider your claim or provide other insurance services.
              </p>
              <h3>Why we use your personal data</h3>
              <p>We collect your personal data to help us with advising on, arranging, underwriting or administering an
                insurance contract or administering a claim under an insurance contract. Specifically:
              </p>
              <p>
                <strong>a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Advising on, arranging and underwriting your policy,
                  including:
                </strong>
              </p>
              <ul type="disc">
                <li>Understanding your insurance requirements to offer you a product that matches your needs and
                  circumstances
                </li>
                <li>Gaining an understanding of the nature of the risk to be covered by the policy&nbsp;including risk
                  modelling and statistical use
                </li>
                <li>Providing competitive and appropriate pricing</li>
                <li>Performing credit or money laundering checks or other checks required by law</li>
                <li>Fraud prevention</li>
              </ul>
              <p><strong>b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Administering your policy, including:</strong></p>
              <ul type="disc">
                <li>Managing any changes to your policy</li>
                <li>Providing and improving services under and associated with the insurance contract as appropriate
                </li>
                <li>Maintaining contact with you, for issues relating to your policy and general customer contact</li>
              </ul>
              <p><strong>c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Administering your claims, including:</strong></p>
              <ul type="disc">
                <li>Registering your claims</li>
                <li>Assessing your claims, including any liaison with third parties potentially involved in your claims,
                  e.g. communications regarding health information
                </li>
                <li>Running due diligence checks e.g. credit or money laundering</li>
                <li>The investigation of fraudulent claims</li>
                <li>The defence of or prosecution of valid and legal claims</li>
              </ul>
              <p><strong>d.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recruitment of current and former employees:</strong>
              </p>
              <ul type="disc">
                <li>Purpose of processing an employment application</li>
                <li>Assess your suitability for employment</li>
                <li>Assessments, tests or occupational profiles</li>
                <li>Providing or requesting employment references</li>
                <li>To carry out background checks</li>
              </ul>
              <p><strong>e.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Further reasons, including:</strong></p>
              <ul type="disc">
                <li>To ensure we comply with any legal or regulatory obligations</li>
                <li>The testing of our systems and processes where imitation data is unavailable. Testing which uses
                  personal data will only by carried out in limited circumstances and only when appropriate safeguards
                  and controls have been put in place
                </li>
                <li>To allow us to perform the essential practice and process of underwriting</li>
                <li>Analysing our clients and the products they select</li>
                <li>For reinsurances purposes</li>
                <li>Processing the enrolment and management of the share scheme</li>
                <li>To audit our business</li>
                <li>Transferring books of business, company sales and reorganisations</li>
                <li>For marketing purposes</li>
              </ul>
              <h3>Where we might collect your personal data from</h3>
              <p>We might collect your personal data from various sources including:</p>
              <ul type="disc">
                <li>You;</li>
                <li>Your family members, employer or representative;</li>
                <li>Other insurance market participants such as, authorised agents, brokers, TSPs, reinsurers, other
                  insurers, legal advisers, loss adjusters and claims handlers;
                </li>
                <li>Credit reference agencies;</li>
                <li>Anti-fraud databases, sanctions lists, court judgements and other databases;</li>
                <li>Government agencies</li>
                <li>In the event of a claim, third parties including any other party to the claim (such as a
                  claimant/defendant), witnesses, experts (including medical experts), loss adjustors, legal
                  professionals, and third party claims handlers.
                </li>
              </ul>
              <p>Which of the above sources apply will depend on your particular circumstances.</p>
              <h3>Our legal bases for processing your personal data</h3>
              <p><strong>We process personal data where necessary in order to:</strong></p>
              <ul type="disc">
                <li>Engage with you when you or someone acting on your behalf asks us for a quote and are considering
                  entering into a contract;
                </li>
                <li>Satisfy our obligations under a contract with you;</li>
                <li>Comply with a legal obligation, such as due diligence and reporting obligations, and responding to
                  binding requests from regulators, law enforcement authorities or other government authorities;
                </li>
                <li>Process data as may be required in the public interest, such as detecting and preventing fraud;</li>
                <li>Pursue our legitimate interests in providing clients with insurance services, improving our
                  services, to ensure we price our products appropriately, to manage risk, to manage our business
                  efficiently, to perform audits, and to maintain accurate records.
                </li>
              </ul>
              <p>We process special category data when you provide explicit consent or when this is necessary to:</p>
              <ul type="disc">
                <li>Advise, arrange, underwrite or administer an insurance policy or administer a claim under an
                  insurance policy;&nbsp;
                </li>
                <li>Protect, investigate, and defend legal claims;</li>
                <li>Exercise a right or comply with an obligation arising in connection with an insurance contract.</li>
              </ul>
              <h3>Who we share personal data with</h3>
              <p>To allow us to meet our obligations and effectively provide our services to you, it may be necessary to
                pass your personal data onto external parties. These external parties may include:
              </p>
              <ul type="disc">
                <li>Credit reference agencies</li>
                <li>Anti-fraud databases</li>
                <li>Claims handlers</li>
                <li>Legal professionals</li>
                <li>Loss adjustors</li>
                <li>External parties involved in the claim</li>
                <li>Private investigators</li>
                <li>The police and law enforcement</li>
                <li>External parties involved in the investigation, defence or prosecution of claims</li>
                <li>Other insurers (under court order or to prevent and detect fraud)</li>
                <li>Regulators and Supervisory Authorities</li>
                <li>Companies that are part of the Beazley group</li>
                <li>Our TSPs suppliers and sub-contractors for the performance of any contract we have with them</li>
              </ul>
              <p>Your data will be shared securely, and only when absolutely necessary. It will never be sold to
                external parties or organisations for marketing purposes.
              </p>
              <h3>International data transfers</h3>
              <p>As Beazley is a global insurer, we may transfer or share your data outside the European Economic Area
                (&quot;EEA&quot;)for our operational procedures, including countries that may not offer an equivalent
                level of protection to that applicable within the EEA. It may also be processed by staff operating
                outside the EEA who are working for us or one of our suppliers. We will always take steps to ensure
                your data is treated and transferred securely with appropriate safeguards and controls in place. We
                will exercise due diligence in selecting the recipients outside the EEA in accordance with Article 44
                of the European Regulation 2016/679 and will require, through appropriate contractual measures (e.g.
                data transfer contracts based on standard European Commission clauses), that they comply with adequate
                organisational and technical security measures to protect personal data and process them only in
                accordance with our instructions and not for other purposes.&nbsp;
              </p>
              <h3>Ongoing storage and use of your personal data</h3>
              <p>We will not keep personal data for longer than necessary for the purpose for which it is
                processed.&nbsp; It will be retained in accordance with our Records Management Policy. Applicable laws
                and regulations may require us to keep records for specific periods of time, subject to certain
                exceptions. We may also need to keep records in order to administer the insurance relationship, to
                fulfil our contractual or statutory obligations or to resolve queries or disputes which may arise.
              </p>
              <h3>Information Security&nbsp;</h3>
              <p>We implement technical and organisational measures to ensure a level of security
                appropriate to the risk to the personal information we process. These measures are aimed at ensuring the
                on-going integrity and confidentiality of personal information. We evaluate these measures on a regular
                basis to ensure the security of the processing.
              </p>
              <h3>Your data subject rights</h3>
              <p>You have the right to:</p>
              <ul type="disc">
                <li>Obtain a copy of your personal data held by Beazley</li>
                <li>Have any incorrect personal data corrected</li>
                <li>Request the erasure of any of your personal data</li>
                <li>Restrict or object to the use of your personal data, including profiling and automated individual
                  decision-making.
                </li>
                <li>Request the personal data you provided to Beazley to be moved to another organisation</li>
              </ul>
              <br />
              <p>If you wish to exercise any of these rights please write to the below address stating your request and
                contact details.
              </p>
              <p>The Data Protection Officer <br />
                Beazley Plc <br />
                22 Bishopsgate <br />
                London <br />
                EC2N 4BQ
              </p>
              <p>If you contact us regarding the exercise of these rights, we will seek to action your wishes. There
                maybe in some cases (particularly where the request relates to the restriction of use of personal data,
                the objecting to the use of personal data or the erasure of the data) reasons why we are not able to
                fully comply with your request, particularly where we are required to keep and use that data to comply
                with contractual, legal or regulatory requirements. &nbsp;
              </p>
              <h3>Right to withdraw consent</h3>
              <p>In the limited circumstances where you may have provided your consent to the collection, processing and
                transfer of your personal information for a specific purpose, you have the right to withdraw your
                consent for that specific processing at any time. To withdraw your consent please contact us. Once we
                have received notification that you have withdrawn your consent, we will no longer process your
                information for the purpose or purposes you originally agreed to, unless we have another legitimate
                basis for doing so in law. &nbsp;
              </p>
              <h3>Complaints</h3>
              <p>Beazley is committed to providing high quality products and services. If you feel that we have not met
                your expectations, we&apos;d like to know so we can put things right for you. You can submit a complaint
                by writing to the address below.
              </p>
              <p>Complaints <br />
                Beazley Plc <br />
                22 Bishopsgate <br />
                London <br />
                EC2N 4BQ
              </p>
              <p>We would expect that any complaint can best be dealt with by contacting us in the first instance, and
                we will take complaints made to us seriously. However, if you wish to complain about our use of your
                personal data, and do not wish to contact us first, you also have the right to complain directly to the
                supervisory authority. Full details on this can be found on the following websites:
              </p>
              <table border="0" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td valign="top" width="178"><strong>UK</strong><br /></td>
                    <td valign="top" width="355">
                      <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">https://ico.org.uk/</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="178"><strong>Ireland</strong></td>
                    <td valign="top" width="355">
                      <a href="https://dataprotection.ie/" target="_blank" rel="noreferrer">
                        https://dataprotection.ie/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="178"><strong>France</strong></td>
                    <td valign="top" width="355">
                      <a href="https://www.cnil.fr/fr" target="_blank" rel="noreferrer">https://www.cnil.fr/fr</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="178"><strong>Germany</strong></td>
                    <td valign="top" width="355">
                      <a href="https://www.bfdi.bund.de/" target="_blank" rel="noreferrer">https://www.bfdi.bund.de/</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="178"><strong>Spain</strong></td>
                    <td valign="top" width="355">
                      <a href="https://www.aepd.es/es">https://aepd.es/es</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="178"><strong>Norway</strong></td>
                    <td valign="top" width="355">
                      <a href="https://www.datatilsynet.no/" target="_blank" rel="noreferrer">
                        https://www.datatilsynet.no
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p />
              <h3>Insurance Industry and Data Processing</h3>
              <p>Further details of how the insurance industry uses and processes data can be found oin the Lloyds
                website via the <a href="https://www.londonmarketgroup.co.uk/gdpr">core uses information notice</a>.
                Please note that this is a third party website and Beazley is not responsible for the content.
              </p>
              <p><a href="https://www.londonmarketgroup.co.uk/gdpr">https://www.londonmarketgroup.co.uk/gdpr</a></p>
              <p>
                {/* eslint-disable max-len */ }
                <a
                  href="https://admin.londonmarketgroup.co.uk/wp-content/uploads/2018/06/LMA-Insurance-Market-Information-Uses-Notice-post-enactment-31-05-2018.pdf"
                >https://admin.londonmarketgroup.co.uk/wp-content/uploads/2018/06/LMA-Insurance-Market-Information-Uses-Notice-post-enactment-31-05-2018.pdf
                </a>
              </p>
              <h3>Automated decision making</h3>
              <p>In some cases we use an automated decision making and profiling process to generate a quote to provide
                you with an insurance service; this process will only use the information which you have provided to us
                and will make an overall assessment of your application. This assessment will consider the level of risk
                involved and if applicable, generate a quote for the insurance service. The automated decision making
                process is regularly tested to ensure it remains fair, effective and unbiased. If you object to the use
                of automated decision making, please call us on the telephone number displayed on the quote generation
                page or contact the Beazley Data Protection Officer&nbsp;at the address provided above. <br /><br />
              </p>
              <h3>Use of cookies</h3>
              <p>Cookies are small text files that are placed on your computer by websites that you visit. They are
                widely used in order to make websites work, or work more efficiently, as well as to provide information
                to the owners of the site.
              </p>
              <p>We use cookies on our websites so that we can track how users navigate through our sites, and in order
                to enable us to evaluate and improve our sites. We use this information to compile statistical data on
                the use of our websites, but the information obtained is used on an anonymous, aggregated basis and you
                cannot be identified from this. &nbsp;Cookies cannot look into your computer and obtain information
                about you or your family or read any material kept on your hard drive and cookies cannot be used to
                identify who you are.
              </p>
              <p>You are not obliged to accept a cookie that we send to you and you can in fact modify your browser so
                that it will not accept cookies. However, if you select this setting you may be unable to access certain
                parts of our site. &nbsp;For more information about cookies including how to set your internet browser
                to reject cookies please follow the instructions below:
              </p>
              <ul>
                <li>
                  <span>
                    <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                      Internet Explorer
                    </a>: Tools button &gt; Internet options &gt; Privacy &gt; Settings &gt; Advanced.
                  </span>
                </li>
                <li>
                  <span>
                    <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                      Mozilla Firefox
                    </a>: Menu button &gt; Options &gt; Privacy &amp; Security &gt; History &gt; Use
                    custom settings for history.
                  </span>
                </li>
                <li>
                  <span>
                    <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en">
                      Google Chrome
                    </a>: More button &gt; Settings &gt; Advanced &gt; Privacy and security &gt; Content
                    settings &gt; Cookies.
                  </span>
                </li>
                <li>
                  <span>
                    <a href="https://support.apple.com/guide/safari/security-ibrw1074/mac">Apple Safari</a>:
                    Preferences &gt; Privacy.&nbsp;
                  </span>
                </li>
              </ul>
              <p><span>The table below explains the cookies we use and why:</span></p>
              <table border="1" cellPadding="0" cellSpacing="3" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td valign="top" width="130"><strong>Cookie</strong><br />
                      WebTrends
                    </td>
                    <td valign="top" width="106"><strong>Name</strong><br />
                      WT_FPB, WT_PBC
                    </td>
                    <td valign="top" width="343"><strong>Purpose</strong><br />
                      These cookies are used to collect information about how visitors use our site. We use the
                      information to compile reports and to help us improve the site. The cookies collect information
                      in an anonymous form, including the number of visitors to the site, where visitors have come to
                      the site from and the pages they visited.
                    </td>
                    <td valign="top" width="196"><strong>More information</strong><br />
                      <a href="https://kb.webtrends.com/articles/Information/Opting-out-of-Tracking-Cookies-1365447872915">
                        Opting out of WebTrends cookies
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="130"><strong>Cookie</strong><br />
                      Google Analytics
                    </td>
                    <td valign="top" width="106"><strong>Name</strong><br />
                      _utma, _utmb, _utmz, _utmc
                    </td>
                    <td valign="top" width="343"><strong>Purpose</strong><br />
                      These cookies are used to collect information about how visitors use our site. We use the
                      information to compile reports and to help us improve the site. The cookies collect information
                      in an anonymous form, including the number of visitors to the site, where visitors have come to
                      the site from and the pages they visited.
                    </td>
                    <td valign="top" width="196"><strong>More information</strong><br />
                      <a href="https://support.google.com/analytics/answer/2838718?hl=en">Google Analytics -
                        safeguarding your data
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="130"><strong>Cookie</strong><br />
                      Windows Internet Information Services&nbsp;
                    </td>
                    <td valign="top" width="106"><strong>Name</strong><br />
                      cs(cookie)
                    </td>
                    <td valign="top" width="343"><strong>Purpose</strong><br />
                      These cookies are used to collect information about how visitors use our site. The cookies collect
                      information in an anonymous form, including the number of visitors to the site, where visitors
                      have come to the site from and the pages they visited.
                    </td>
                    <td valign="top" width="196"><strong>More information</strong><br />
                      <a href="https://privacy.microsoft.com/en-us/privacystatement">Microsoft privacy policy</a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="middle" width="130"><strong>Cookie</strong><br />
                      .Net or ASPX cookie when users log into the Lawyers and A&amp;E risk management&nbsp; sites
                    </td>
                    <td valign="top" width="106"><strong>Name</strong><br />
                      IGXSessionState
                    </td>
                    <td valign="top" width="343"><strong>Purpose</strong><br />
                      These cookies are used to track whether a user has logged in to the risk management site. If the
                      cookie exists, access to the site is allowed. If the cookie does not exist, the user will receive
                      the login screen.
                    </td>
                    <td valign="top" width="196"><strong>More information</strong><br />
                      <a href="https://docs.microsoft.com/en-us/previous-versions/aspnet/ms178194(v=vs.100)">ASP.NET
                        cookie overview
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" width="130"><strong>Cookie</strong><br />
                      LinkedIn
                    </td>
                    <td valign="top" width="106"><strong>Name</strong><br />
                      Lidc - Used for outing,&nbsp;bcookie - Browser ID cookie, bscookie - Secure browser ID
                      cookie,&nbsp;L1c - Browser ID cookie, BizoID - LinkedIn Ad analytics, BizoData - LinkedIn Ad
                      analytics, BizoUserMatchHistory - LinkedIn Ad analytics, BizoNetworkPartnerIndex - LinkedIn Ad
                      analytics&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </td>
                    <td valign="top" width="343"><strong>Purpose</strong><br />
                      These cookies are used for campaign reporting to track conversions and provide additional insight
                      on how users interact with our ads.
                    </td>
                    <td valign="top" width="196"><strong>More information</strong><br />
                      <strong>
                        <a href="https://www.linkedin.com/legal/cookie-policy?trk=uno-reg-guest-home-cookie-policy">
                          LinkedIn cookie policy
                        </a>
                      </strong><br /><br /><br />
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>Web analytics</h3>
              <p>In order to develop our site in line with our customers&apos; needs, Beazley keeps a track on which
                pages on our website are visited most frequently and how long visitors spend on our site. We use this
                information to help improve the site. <br /><br />
                We never gather other information from your disk or computer. We collect a copy of the data held by the
                cookie for inclusion in any analysis. We use full SSL protocols when collecting visitor information on
                secure pages; this ensures that the site&apos;s security is not compromised. We encrypt all transmitted
                visitor information (even from non-secure pages), so no-one else can read the information we gather. We
                use Google Analytics, a web analytics service provided by Google, Inc. and WebTrends to analyze website
                traffic. Google Analytics and WebTrends set a cookie in order to evaluate your use of this site. Google
                and WebTrends store the information collected by the cookie on servers in the United States, European
                Union Member States and other countries. Google and WebTrends may also transfer this information to
                third parties where required to do so by law, or where such third parties process the information on
                Google&apos;s and WebTrends&apos; behalf. Google and WebTrends will use this information for the
                purpose of evaluating your use of the website, compiling reports on website activity for website
                operators and providing other services relating to website activity and internet usage.&nbsp;Google and
                WebTrends will not associate your IP address with any other data held by Google or WebTrends. Beazley
                use Google Analytics and WebTrends to optimise this site and improve the service we provide to our
                visitors. More information about how to reject or delete this cookie may be found here: <br />
                <a href="http://www.google.com/intl/en/privacypolicy.html" target="_blank" rel="noreferrer"><br />
                  http://www.google.com/intl/en/privacypolicy.html
                </a>
                <a href="https://www.webtrends.com/legal/privacy-statement/" target="_blank" rel="noreferrer"><br />
                  http://webtrends.com/privacy-policy/
                </a>
              </p>
              <h3>YouTube cookies</h3>
              <p>We embed videos from our official YouTube channel using YouTube&apos;s privacy-enhanced mode. This
                mode may set cookies on your computer once you click on the YouTube video player, but YouTube will not
                store personally-identifiable cookie information for playbacks of embedded videos using the
                privacy-enhanced mode. To find out more please visit&nbsp;
                <a
                  href="http://www.google.com/support/youtube/bin/answer.py?hl=en-GB&amp;answer=171780"
                  title="External link"
                  target="_blank"
                  rel="noreferrer"
                >YouTube&apos;s embedding videos information page
                </a>.
              </p>
              <h3>Search engine</h3>
              <p>The search engine on our website is designed to be powerful and easy to use . &nbsp;The search is made
                possible by a piece of hardware (a search &apos;appliance&apos;) supplied by Autonomy that is plugged
                into our server and continuously indexes the content on our site. &nbsp;All search requests are handled
                by the appliance and the information is not passed on to any third party, including Autonomy.
              </p>
              <h3>Website surveys</h3>
              <p>From time to time, we may invite our Site visitors to participate in an online survey about our online
                services. Participation is entirely optional and any information we collect is only used to improve the
                products and services we offer to our visitors. We may collect your name, company and email address.
              </p>
              <h3>Changes to this privacy policy</h3>
              <p>If we change our privacy policy in any way, we will post these changes on this page. You are
                responsible for checking this privacy policy whenever you access the Site.
              </p>
              <p style={{ textAlign: 'center' }}><strong><em>Last updated 07 November 2020</em></strong></p>
            </div>
          </Col>
        </Row>
      </Grid>
    </WhatWeDoSection>
  </>
);

export default PrivacyStatement;
