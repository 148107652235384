import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Button from 'components/Button';
import CustomLink from 'components/Link';
import CustomHashLink from 'components/HashLink';
import { ReactComponent as Logo } from 'images/BHI_logo.svg';
import { Link } from 'react-router-dom';
import {
  Wrapper, LinkWrapper, FooterP, FooterText,
} from './StyledComponents';

import Dialog from '../Dialog';

const Footer = () => {
  const [dialogShow, setDialogShow] = useState(false);

  return (
    <Wrapper>
      <Grid>
        <Row middle="xs" between="xs" className="footer-space">
          <Col xs={6} style={{ textAlign: 'left' }}>
            <Logo />
          </Col>
          <Col xs={6} className="hidden-md hidden-lg hidden-xl" style={{ textAlign: 'right' }}>
            <a href="https://portal.bhidigital.com/auth/login" target="_blank" rel="noreferrer">
              <Button title="Sign In" link="link" />
            </a>
          </Col>
          <Col xs={6} className="hidden-xs hidden-sm">
            <LinkWrapper>
              <CustomLink link="/about-us" text="About Us" />
              <CustomLink link="/our-products" text="Our Products" />
              <CustomHashLink link="/about-us#contact-us" text="Contact Us" />
              <a
                style={{ justifySelf: 'end' }}
                href="https://portal.bhidigital.com/auth/login"
                target="_blank"
                rel="noreferrer"
              >
                <Button title="Sign In" link="link" />
              </a>
            </LinkWrapper>
          </Col>
        </Row>

        <Row className="hidden-md hidden-lg hidden-xl" style={{ paddingBottom: 32 }}>
          <Col xs={12}>
            <CustomLink link="/about-us" text="About Us" />
          </Col>
        </Row>

        <Row className="hidden-md hidden-lg hidden-xl" style={{ paddingBottom: 32 }}>
          <Col xs={12}>
            <CustomLink link="/our-products" text="Our Products" />
          </Col>
        </Row>

        <Row className="hidden-md hidden-lg hidden-xl" style={{ paddingBottom: 64 }}>
          <Col xs={12}>
            <CustomLink link="/about-us#contact-us" text="Contact Us" />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={7}>
            <FooterText>
              <p>
                BHI Digital, LLC, 3350 Virginia Street, 2nd Floor, Suite 272, Coconut Grove, FL 33133, United States
              </p>
              <p>BHI Digital Insurance Services California Licence Number 6001730</p>
              <p>© { new Date().getFullYear()} BHI Digital, LLC</p>
            </FooterText>
          </Col>
          <Col xs={12} md={5} className="button-wrapper">
            <Link to="/legal-information">
              <FooterP>Legal Information</FooterP>
            </Link>
            <Link to="/privacy-and-cookies-statement">
              <FooterP>Cookies and Privacy Statement</FooterP>
            </Link>
            <button type="button" aria-hidden="true" onClick={() => setDialogShow(true)}>
              <p>Disclaimer</p>
            </button>
          </Col>
        </Row>
      </Grid>
      <Dialog onHide={() => setDialogShow(false)} show={dialogShow} />
    </Wrapper>
  );
};

export default Footer;
