// @flow
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CustomRoutes } from 'router/Routes';
import Footer from 'components/Footer';
import DynamicScrollToTop from 'components/DynamicScrollToTop';

const App = () => (
  <BrowserRouter>
    <DynamicScrollToTop />
    <CustomRoutes />
    <Footer />
  </BrowserRouter>
);

export default App;
