// @flow
import React from 'react';
import Modal from 'react-bootstrap/Modal';

type Props = {
  onHide: Function,
  show: boolean,
}

const Dialog = (props: Props) => (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    animation
    keyboard
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Disclaimer
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        The descriptions contained in these pages are for preliminary informational purposes only and coverages are
        available in the US only on a surplus lines basis through licensed surplus lines brokers underwritten by
        Beazley syndicates at Lloyd’s of London. The exact coverage afforded by the products described herein is
        subject to and governed by the terms and conditions of each policy issued. The publication and delivery
        of the information contained herein is not intended as a solicitation for the purchase of insurance on any
        US risk. BHI Digital, LLC. is licensed and regulated by insurance regulatory authorities in the respective
        states of the US and transacts business in the State of California as BHI Digital Insurance Services
        (License#: 6001730).
      </p>
    </Modal.Body>
  </Modal>
);
export default Dialog;
