import styled from 'styled-components';
import { device } from 'constants/mediaQueries';

const HeroSection = styled.div`
  background: ${({ theme }) => theme.colors.mist};
  padding-top: 216px;
  padding-bottom: 32px;

  @media ${device.mobileL} {
    padding-bottom: 64px;
  }
  
  .row {
    height: initial;

    @media ${device.mobileL} {
      height: 420px;
    }
  }
`;

const WhatWeDoSection = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.mobileL} {
    padding-bottom: 0;
  }


  img {
    margin-bottom: initial;

    @media ${device.mobileL} {
      margin-bottom: -56px;
    }
  }
`;

const IntroPara = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  @media ${device.mobileL} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const OurClientsSection = styled.div`
  background: ${({ theme }) => theme.colors.almond};
  padding-bottom: 64px;
  padding-top: 96px;

  @media ${device.mobileL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  
  h2 {
    padding-bottom: 0;
  }

  p:first-child {
    padding-bottom: 64px;
  }
  
  svg {
    padding-top: 48px;
    
    @media ${device.mobileL} {
      padding-top: initial;
    }
  }
`;

const ContactSection = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.mobileL} {
    padding-top: 96px;
    padding-bottom: 128px;
  }
  
  p:first-child {
    font-size: 24px;
    line-height: 32px;
  }
  
  p:last-child {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
  
  .kevin {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 8px;
  }
  
  img {
    padding-top: 64px;

    @media ${device.mobileL} {
      padding-bottom: 0;
      padding-top: initial;
    }
  }
  
  a {
    color: ${({ theme }) => theme.colors.secondary};
    padding-bottom: 48px;
    text-decoration: underline;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  a:last-child {
    padding-bottom: 0;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  width: 100%;
  margin: 8px 0;
`;

export {
  HeroSection,
  WhatWeDoSection,
  IntroPara,
  OurClientsSection,
  ContactSection,
  Line,
};
