import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  transition: .5s all;
  
  .active {
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    margin-top: 8px;
    position: absolute;
    width: 100%;
  }

 &:hover div {
  position: absolute;
  width: 100%;
  border: 1px solid #FFFFFF;
  margin-top: 8px;

   ${({ light }) => light && css`
     border-color: ${({ theme }) => theme.colors.secondary};
  `}
 }
`;

type Props = {
  link: string,
  text: string,
  active?: boolean,
  light?: boolean | string,
}

const CustomLink = ({
  link = '', text, active, light,
}: Props) => (
  <StyledLink to={link} light={light}>
    {text}
    <div className={active ? 'active' : ''} />
  </StyledLink>
);

export default CustomLink;
