import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Aos from 'aos';

import WindImg from 'images/products-tom-barrett-unsplash.jpg';
import EarthquakeImg from 'images/products-shefali-lincoln-unsplash.jpg';
import TerrorImg from 'images/terror-image.jpg';
import NavBar from 'components/NavBar';
import Button from 'components/Button';
import PieChart from 'components/PieChart';
import ArrowRight from 'images/icons/arrow-right.png';
import Dashboard from 'images/bhi-digital-dashboard.jpg';
import {
  HeroSection,
  WindSection,
  TerrorSection,
  OurClientsSection,
  EarthquakeSection,
  PlatformSection,
  Line,
} from './StyledComponents';
import 'aos/dist/aos.css';

const OurProducts = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      disable: 'mobile',
      once: true,
    });
  }, []);

  return (
    <>
      <NavBar light />
      <HeroSection>
        <Grid>
          <Row bottom="xs">
            <Col xs={12} md={9}>
              <h1 data-aos="fade-right">Specialty Insurance Made Simple</h1>
              <p>
                BHI Digital is committed to service outperformance for ‘small, simple risks’. Our current product
                offering encompasses Wind and Earthquake Deductible Buybacks and Standalone Terrorism insurance. We
                combine speed with personalized service and offer multichannel distribution via portal access, API and
                traditional ‘submit to quote’.
              </p>
            </Col>
          </Row>
        </Grid>
      </HeroSection>
      <WindSection>
        <Grid>
          <Row>
            <Col xs={12} md={6}>
              <div>
                <h2>Wind Deductible<br />Buybacks (WDBB)</h2>
                <p>
                  This coverage allows an Insured to lower their retention for catastrophic wind perils in the USA,
                  including hurricane, named storm, hail and tornado. The policy incorporates a ‘follow form’ wording
                  which links it to the Insured’s overlying all risk property coverage.
                </p>
                <p>
                  The indemnity responds in the event of a covered claim that results from the peril of wind as defined
                  in the overlying policy wording, which the Wind Deductible Buyback ‘follows’.
                </p>
                <a href={`${process.env.PUBLIC_URL}/pdf/BHI-flyer WDBB.pdf`} target="_blank" rel="noreferrer">
                  <Button title="Download the flyer" />
                </a>
              </div>
            </Col>
            <Col xs={1} />
            <Col xs={12} md={5}>
              <img src={WindImg} alt="wdbb" />
            </Col>
          </Row>
        </Grid>
      </WindSection>
      <OurClientsSection>
        <Grid>
          <Row center="xs">
            <Col xs={12} md={9}>
              <p className="size-18">About our clients</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1} />
            <Col xs={12} md={5}>
              <h2>Occupancy</h2>
              <Line />
              <p style={{ paddingBottom: 48 }}>
                From Hotels to Religious Institutions, Homeowners Associations & Permanent Dwellings, Restaurants
                and the Entertainment industry, Business Services to Retail and Wholesale Trades – BHI Digital has
                live in-force policies with over 36 distinct occupancy classes.
              </p>

              <h2>Insured Value Range</h2>
              <Line />
              <p>We currently insure property values ranging from $250K per insured up to $500M</p>
            </Col>
            <Col xs={12} md={6}>
              <PieChart />
              <p style={{ textAlign: 'center', paddingTop: 16 }}>Client profiles</p>
            </Col>
          </Row>
        </Grid>
      </OurClientsSection>
      <EarthquakeSection>
        <Grid>
          <Row>
            <Col xs={12} md={5} className="hidden-xs">
              <img data-aos="fade-up" src={EarthquakeImg} alt="eqdbb" />
            </Col>
            <Col xs={1} />
            <Col xs={12} md={6}>
              <h2>Earthquake Deductible<br />Buyback (EQDBB)</h2>
              <p>
                This coverage allows an Insured to lower their retention for Earthquake perils in the USA. The policy
                incorporates a ‘follow form’ wording which links it to the Insured’s overlying all risk property
                coverage.
              </p>
              <p>
                The indemnity responds in the event of a covered claim that results from the peril of wind as defined
                in the overlying policy wording, which the Earthquake Deductible Buyback ‘follows’.
              </p>
              <a href={`${process.env.PUBLIC_URL}/pdf/BHI-flyer EQDBB.pdf`} target="_blank" rel="noreferrer">
                <Button title="Download the flyer" />
              </a>
            </Col>
            <Col xs={12} className="hidden-md hidden-lg hidden-xl">
              <img data-aos="fade-up" src={EarthquakeImg} alt="eqdbb" />
            </Col>
          </Row>
        </Grid>
      </EarthquakeSection>
      <TerrorSection>
        <Grid>
          <Row>
            <Col xs={12} md={6}>
              <div>
                <h2>Standalone<br />Terrorism insurance</h2>
                <p>
                  BHI Digital offers a standalone terrorism insurance solution as an alternative to the TRIPRA coverage
                  sourced via property placements. The product is targeted at small to medium sized commercial Insureds
                  (SME). These Insureds will often be situated in US states which have exposure to earthquake,
                  hurricanes and/or convective storms and rely on the E&S insurance marketplace for property coverage
                  needs.
                </p>
                <p>
                  Our policy offers a broader coverage solution based upon an Insured’s specific exposure to a terrorist
                  event, priced independent to property insurers&apos; TRIPRA terms.
                </p>
                <a href={`${process.env.PUBLIC_URL}/pdf/BHI-flyer TER.pdf`} target="_blank" rel="noreferrer">
                  <Button title="Download the flyer" />
                </a>
              </div>
            </Col>
            <Col xs={1} />
            <Col xs={12} md={5}>
              <img data-aos="fade-up" src={TerrorImg} alt="Terrorism" />
            </Col>
          </Row>
        </Grid>
      </TerrorSection>
      <PlatformSection>
        <Grid>
          <Row center="xs">
            <Col xs={12} md={10}>
              <h2 data-aos="fade-right">Sign into the portal</h2>
              <a href="https://portal.bhidigital.com/auth/login" target="_blank" rel="noreferrer">
                <p className="size-18"><img src={ArrowRight} alt="arrow" /> Sign in</p>
              </a>
              <img data-aos="fade-up" src={Dashboard} alt="Dashboard" />
            </Col>
          </Row>
        </Grid>
      </PlatformSection>
    </>
  );
};

export default OurProducts;
