// @flow
export default {
  colors: {
    primary: '#121F34',
    secondary: '#0C3A83',
    mist: '#E6EAEF',
    honey: '#F6AE2D',
    honeyDarker: '#C48C24',
    almond: '#E9D5C3',
    text: '#000000',
    link: '#FFFFFF',
    container: '#FFFFFF',
  },
  font: {
    fontFamily: 'Helvetica Neue, Roboto, sans-serif',
    weight: {
      light: 100,
      normal: 400,
      bold: 700,
    },
    size: {
      body: '18px',
    },
    letterSpacing: '0.02em',
  },
};
