// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from 'components/Button';

import { Ul } from './StyledComponents';

type Props = {
  open: boolean | string;
  light: boolean | string;
};

const DropdownMenu = ({ open, light }: Props) => (
  <Ul open={open} light={light}>
    <li><Link to="/">Home</Link></li>
    <li><Link to="/about-us">About Us</Link></li>
    <li><Link to="/our-products">Our Products</Link></li>
    <li><HashLink to="/about-us#contact-us">Contact Us</HashLink></li>
    <li>
      <a href="https://portal.bhidigital.com/auth/login" target="_blank" rel="noreferrer">
        <Button title="Sign In" />
      </a>
    </li>
  </Ul>
);

export default DropdownMenu;
