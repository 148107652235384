// @flow
import { css, createGlobalStyle } from 'styled-components';

export const fonts = css`
  body {
    background-color: ${({ theme }) => theme.colors.background};
    font-family: ${({ theme }) => theme.font.fontFamily};
    font-size: ${({ theme }) => theme.font.size.body};
    letter-spacing: ${({ theme }) => theme.font.letterSpacing};
  }
  
  h1 {
    font-size: 48px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.colors.primary};
    line-height: 52px;
    padding-bottom: 32px;
    letter-spacing: ${({ theme }) => theme.font.letterSpacing};
  }

  h2 {
    font-size: 36px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 42px;
    padding-bottom: 32px;
    letter-spacing: ${({ theme }) => theme.font.letterSpacing};
  }
  
  h3 {
    font-size: 24px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.colors.primary};
    line-height: 32px;
    padding-bottom: 8px;
    padding-top: 48px;
  }
  
  p, span, td {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    line-height: 24px;
    padding-bottom: 32px;
    letter-spacing: ${({ theme }) => theme.font.letterSpacing};
  }
  
  a {
    color: ${({ theme }) => theme.colors.link};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    text-decoration: none;
  }

  @media only screen and (max-width: 414px) {
    h1 {
      font-size: 30px;
      line-height: 40px;
    }
    
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
    
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const Fonts = createGlobalStyle`${fonts}`;

export default fonts;
