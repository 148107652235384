import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.honey};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  border: none;
  padding: 14px 34px;
  cursor: pointer;

  transition: .5s all;

  &:hover {
    background-color: ${({ theme }) => theme.colors.honeyDarker};
  }
`;

type Props = {
  title: string,
  type?: string,
  // link: string,
}

const Button = (
  {
    title,
    type = 'button',
    // link = '',
  }: Props,
) => (
  <StyledButton
    type={type}
  >
    {title}
  </StyledButton>
);

export default Button;
