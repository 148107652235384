import styled from 'styled-components';
import { device } from 'constants/mediaQueries';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding-top: 64px;
  padding-bottom: 64px;
  
  .footer-space {
    padding-bottom: 32px;

    @media ${device.mobileL} {
      padding-bottom: 64px;
    }
  }
  
  p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 16px;
  }
  
  p:last-child {
    padding-bottom: 0;
  }
  
  svg {
    width: initial;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
  
  a {
    display: none;
    @media ${device.mobileL} {
      display: block;
    }
  }
  
  a:hover {
    color: #FFFFFF;
  }
`;

const FooterText = styled.div`
  margin-bottom: 30px;
  @media ${device.mobileL} {
    margin-bottom: initial;
  }
`;

const FooterP = styled.p`
  margin-right: 20px;
`;

export {
  Wrapper,
  LinkWrapper,
  FooterText,
  FooterP,
};
