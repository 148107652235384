import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import NavBar from 'components/NavBar';
import { WhatWeDoSection } from './StyledComponents';
import 'aos/dist/aos.css';

const LegalInformation = () => (
  <>
    <NavBar light />
    <WhatWeDoSection>
      <Grid>
        <Row middle="xs">
          <Col xs={12} md={9}>
            <div>
              <h2>Legal Information</h2>
              <p>BHIDigital.com Website Conditions of Use&nbsp;</p>
              <p>The BHIDigital.com website (&quot;the website&quot;) is published by Beazley plc (&quot;us&quot;
                or &quot;we&quot;) on behalf of all
                Beazley companies.
              </p>
              <p>Please read our conditions of use carefully as by using the website you will be taken to have agreed
                to be bound by them. We reserve the right to vary the conditions of use at any time and will post any
                variations here. You are advised to review the conditions of use on a regular basis as you will be
                deemed to have accepted variations if you continue to use the website after they have been posted.
              </p>
              <p>We take every care and precaution to ensure that information published on the website is accurate
                when posted and regularly updated, but we do not guarantee its accuracy and we may change the
                information at any time without notice.
              </p>
              <p>The information, wording and sample forms contained in the website are for your information only
                and are not an invitation to invest in the shares of, or any other products or services or otherwise
                deal in these or enter into a contract with Beazley Group plc or any other company. The information
                provided shall not be binding on us and should not be relied upon in connection with any investment
                decision. Changes to or improvements in our products or services may be made at any time without
                notice.
              </p>
              <p>[The Investor Information on the website is published by Investis Limited, and separate terms and
                conditions apply to those pages.]
              </p>
              <p>You acknowledge that any reliance on any information or other material posted on the website is
                entirely at your own risk. You should check all information directly with us and should not rely on
                any material contained on the website as a statement, representation of fact or warranty.
              </p>
              <p>Policy wordings included on this website are for information purposes only. You accept that you
                use the wordings at your own risk and, to the extent permitted by law, you waive any rights you
                have or may have against us in respect of your use of the wordings on this website.
              </p>
              <p>The descriptions contained in these pages are for preliminary informational purposes only.
                Coverages will vary depending on individual state law requirements and may be unavailable in some
                states. The exact coverage afforded by the products described on this website is subject to and
                governed by the terms and conditions of each policy issued.&nbsp; The publication and delivery
                of the information contained herein is not intended as a solicitation for the purchase of insurance
                on any US risk.
              </p>
              <p>WE PUBLISH THE WEBSITE &quot;AS IS&quot; WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO
                THE OPERATION OF THE WEBSITE, THE ACCURACY OF THE INFORMATION OR THE PRODUCTS OR SERVICES REFERRED TO
                ON THE WEBSITE (IN SO FAR AS SUCH WARRANTIES MAY BE EXCLUDED UNDER ANY RELEVANT LAW) AND WE SHALL
                NOT BE LIABLE FOR ANY LOSSES OR DAMAGE THAT MAY RESULT FROM USE OF THE WEBSITE AS A CONSEQUENCE
                OF ANY INACCURACIES IN, OR ANY OMISSIONS FROM, THE INFORMATION WHICH THEY MAY CONTAIN.
              </p>
              <p>You are responsible for complying with any legal restrictions applicable to your jurisdiction. Access
                to the website will not constitute an offer in jurisdictions in which it would be illegal to make
                such an offer and in such circumstances it will be deemed to have been sent for information
                purposes only.
              </p>
              <p>Copyright in these pages is owned by Beazley Group plc except where otherwise indicated by a
                third party&apos;s proprietary notice. Images, trade marks and brands are also protected by
                intellectual property laws and may not be reproduced or appropriated in any manner without
                written permission of their respective owners. Unless specifically prohibited by a notice published
                on any page, you may make a print copy of such parts of the website as you may reasonably require
                for your own personal use provided that any copy has attached to it any relevant proprietary
                notices and/or disclaimers. All other use is prohibited.
              </p>
              <p>Content and information provided by third parties is identified clearly where it appears. We
                publish this content as supplied to us and we are not responsible for its accuracy or timeliness.
                You must take appropriate steps to verify this information before acting upon it.
              </p>
              <p>We are not responsible for the content of any other website from which you have accessed the
                website or to which you may hyperlink from the website and cannot be held liable for any loss or
                damage you incur as a result of your use of any other site from which you hyperlink to or from
                the website.
              </p>
              <p>We use all reasonable security measures to protect our website but it is impossible to protect
                totally against the abuse of the website (including but not limited to viruses or malicious attacks)
                by third parties, and you agree that it is your own responsibility to protect yourself against such
                risks.
              </p>
              <p>Due to the nature of the internet and the need for updates and maintenance of the website, we do
                not guarantee continuous or uninterrupted access to the website without any faults.
              </p>
              <p>These conditions of use are governed by the laws of England and Wales and you agree that the
                English courts shall have exclusive jurisdiction in any dispute.
              </p>
              <p>To the extent that any part of these conditions of use is found to be invalid, unlawful or
                unenforceable by any court of competent jurisdiction such part shall to that extent be severed
                from the remaining terms all of which shall remain in full force and effect as permitted by
                law.&nbsp;
              </p>
              <p>© Beazley plc</p>
              <p>Beazley plc - Incorporated and registered in England and Wales<br />
                Registered office: 22 Bishopsgate, London, EC2N 4BQ<br />
                Registered number: 9763575
              </p>
              <p>Beazley Insurance dac - Incorporated and registered in Ireland<br />
                Registered office: 2 Northwood Avenue, Northwood Park, Dublin, 9<br />
                Registered number: 464758
              </p>
              <p>Beazley Furlonge Limited (Company Registration Number: 01893407 and VAT Number: 649 2754 03) is
                registered in England and Wales with its Registered Office at 22 Bishopsgate, London, EC2N 4BQ. Beazley
                Furlonge Limited is authorised by the Prudential Regulation Authority and regulated by the Financial
                Conduct Authority and the Prudential Regulation Authority (Firm Reference Number: 204896).
              </p>
              <p>Beazley Solutions Limited (Company Registration Number:03385161) is registered in England and
                Wales with its Registered Office at 22 Bishopsgate, London, EC2N 4BQ. Beazley Solutions Limited is an
                appointed representative of Beazley Furlonge Limited which is authorised by the Prudential Regulation
                Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.
              </p>
              <p>Beazley Underwriting Services Limited (Company Registration Number: 04043270) is registered in
                England and Wales with its Registered Office at 22 Bishopsgate, London, EC2N 4BQ. Beazley Underwriting
                Services Limited is authorised and regulated by the Financial Conduct Authority (Firm Reference Number:
                309153).
              </p>
              <p>Financial Conduct Authority (FCA) and Prudential Regulation Authority (PRA) authorisation can be
                checked on the Financial Services Register at:
                <a target="_blank" href="http://www.fsa.gov.uk/register/home.do" rel="noreferrer">
                  http://www.fsa.gov.uk/register/home.do
                </a>.
              </p>
              <p>Please contact us at the address below should you have any queries:</p>
              <p>Beazley plc<br />
                22 Bishopsgate<br />
                London<br />
                EC2N 4BQ<br />
                United
                Kingdom <br />
                Email: <a target="_blank" href="mailto:info@beazley.com" rel="noreferrer">info@beazley.com</a>
              </p>
            </div>
          </Col>
        </Row>
      </Grid>
    </WhatWeDoSection>
  </>
);

export default LegalInformation;
