import styled from 'styled-components';
import { device } from 'constants/mediaQueries';

const HeroSection = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding-top: 216px;
  padding-bottom: 64px;
  
  .row {
    height: initial;

    @media ${device.mobileL} {
      height: 420px;
    }
  }
  
  h1 {
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;

    @media ${device.mobileL} {
      font-size: 60px;
      line-height: 72px;
    }
  }
  
  img {
    padding: 8px;
    width: 34px;
  }
`;

const IntroSection = styled.div`
  padding-bottom: 46px;

  @media ${device.mobileL} {
    padding-bottom: 74px;
  }


  img {
    margin-top: -46px;
    
    @media ${device.mobileL} {
      margin-top: -64px;
    }
  }
`;

const IntroPara = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  @media ${device.mobileL} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const SpecialitySection = styled.div`
  background: ${({ theme }) => theme.colors.almond};
  padding-top: 64px;

  @media ${device.mobileL} {
    padding-top: 96px;
  }
  
  p {
    padding-bottom: 32px;
  }
  
  p img {
    width: 16px;
    margin-right: 4px;
  }

  img:last-child {
    margin-top: 0;

    @media ${device.mobileL} {
      margin-bottom: -3px;
    }
  }
`;

const UnderwriteSection = styled.div`
  padding-top: 64px;
  padding-bottom: 23px;

  @media ${device.mobileL} {
    padding-top: 96px;
    padding-bottom: 22px;
  }
  
  .row:first-child {
    padding-bottom: 0;

    @media ${device.mobileL} {
      padding-bottom: 46px;
    }
  }

  p:first-child {
    padding-bottom: 16px;
  }
  
  svg {
    height: 100%;
    padding-bottom: 32px;

    @media ${device.mobileL} {
      padding-bottom: 0;
    }
  }
  
  p img {
    width: 16px;
  }
  
  img {
    padding-bottom: 64px;

    @media ${device.mobileL} {
      padding-bottom: 0;
    }
  }
  
  p img {
    padding-bottom: 0;
  }
`;

const CityList = styled.div`
  p {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 16px;

    @media ${device.mobileL} {
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  p:first-child {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 16px;

    @media ${device.mobileL} {
      padding-bottom: 32px;
    }
  }
`;

const CoverholderWrapper = styled.div`
  width: 100%;
  padding-right: 35px;
  
  svg {
    width: 200px;
    padding-bottom: 13px;
  }
`;

export {
  HeroSection,
  IntroSection,
  IntroPara,
  SpecialitySection,
  UnderwriteSection,
  CityList,
  CoverholderWrapper,
};
