import styled from 'styled-components';
import { device } from 'constants/mediaQueries';

const WhatWeDoSection = styled.div`
  padding-top: 164px;
  padding-bottom: 64px;

  @media ${device.mobileL} {
    padding-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export {
  WhatWeDoSection,
};
