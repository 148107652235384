import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Aos from 'aos';

import CityImg from 'images/image2.jpg';
import ContactImg from 'images/image3.jpg';
import NavBar from 'components/NavBar';
import PieChart from 'components/PieChart';
import {
  HeroSection,
  WhatWeDoSection,
  OurClientsSection,
  ContactSection,
  Line,
} from './StyledComponents';
import 'aos/dist/aos.css';

const AboutUs = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      disable: 'mobile',
      once: true,
    });
  }, []);

  return (
    <>
      <NavBar light />
      <HeroSection>
        <Grid>
          <Row bottom="xs">
            <Col xs={12} md={9}>
              <h1 data-aos="fade-right">BHI Digital is a specialist small to mid-market Coverholder</h1>
              <p>
                Founded in March 2020, we design and build specialist insurance products, which are underwritten via
                a proprietary technology & analytics-led Coverholder platform. On an E&S basis, we are licensed to
                underwrite US-domiciled risks in all 50 States.
              </p>
            </Col>
          </Row>
        </Grid>
      </HeroSection>
      <WhatWeDoSection>
        <Grid>
          <Row middle="xs">
            <Col xs={12} md={6}>
              <div>
                <h2>What we do</h2>
                <p>
                  BHI Digital was formed to make access to specialty insurance products simple. We are committed to
                  service outperformance for ‘small, simple risks’.
                </p>
                <p>
                  Our current product offering encompasses Property Difference in Conditions products and Standalone
                  Terrorism insurance with several new product offerings planned for { new Date().getFullYear()}. We
                  combine speed with personalized service and offer multichannel distribution via portal access, APIs
                  and more traditional ‘submit to quote’ methods.
                </p>
              </div>
            </Col>
            <Col xs={1} />
            <Col xs={12} md={5}>
              <img src={CityImg} alt="Intro" />
            </Col>
          </Row>
        </Grid>
      </WhatWeDoSection>
      <OurClientsSection>
        <Grid>
          <Row center="xs">
            <Col xs={12} md={9}>
              <p className="size-18">About our clients</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1} />
            <Col xs={12} md={5}>
              <h2>Occupancy</h2>
              <Line />
              <p style={{ paddingBottom: 48 }}>
                ‘From Hotels to Religious Institutions, Homeowners Associations & Permanent Dwellings, Restaurants
                and the Entertainment industry, Business Services to Retail and Wholesale Trades’ – BHI Digital has
                live in-force policies with over 36 distinct occupancy classes.
              </p>

              <h2>Insured Value Range</h2>
              <Line />
              <p>We currently insure Property values ranging from $250K per insured up to $500M</p>
            </Col>
            <Col xs={12} md={6}>
              <PieChart />
              <p style={{ textAlign: 'center', paddingTop: 16 }}>Client profiles</p>
            </Col>
          </Row>
        </Grid>
      </OurClientsSection>
      <ContactSection id="contact-us">
        <Grid>
          <Row>
            <Col xs={12} md={4}>
              <h2>BHI Digital, LLC</h2>
              <p style={{ paddingBottom: 16 }}>
                3350 Virginia Street<br />
                2nd Floor, Suite 272, Coconut Grove<br />
                FL 33133<br />
                United States
              </p>

              <h3>General Enquiries</h3>
              <p className="kevin">Kevin Ware</p>
              <a href="mailto:kware@bhi.digital" target="_blank" rel="noreferrer">kware@bhi.digital</a>

              <p className="kevin" style={{ marginTop: 32 }}>Raquelle Bourgeois</p>
              <a href="mailto:rbourgeois@bhi.digital" target="_blank" rel="noreferrer">rbourgeois@bhi.digital</a>

              <h3>Report a claim</h3>
              <a href="mailto:claims@bhi.digital" target="_blank" rel="noreferrer">claims@bhi.digital</a>

              <h3>Submit your risks to</h3>
              <a href="mailto:submissions@bhi.digital" target="_blank" rel="noreferrer">submissions@bhi.digital</a>
            </Col>
            <Col xs={0} md={1} />
            <Col xs={12} md={7} data-aos="fade-up">
              <img src={ContactImg} alt="Miami" />
            </Col>
          </Row>
        </Grid>
      </ContactSection>
    </>
  );
};

export default AboutUs;
