import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-deferred';
import theme from 'styles/theme';

const options = {
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
      label: () => '',
    },
    backgroundColor: '#FFF',
    titleFontSize: 16,
    titleFontColor: theme.colors.primary,
    bodyFontColor: '#000',
    bodyFontSize: 14,
    displayColors: false,
  },
  plugins: {
    deferred: {
      enabled: true,
      xOffset: 150,
      yOffset: '50%',
      delay: 500,
    },
  },
};

const data = {
  labels: [
    'Entertainment and Recreation',
    'General Commercial',
    'General Industrial',
    'General Services',
    'Health Care Service',
    'Multi-Family Dwelling - Homeowners Association',
    'Permanent Dwelling (multi family housing)',
    'Professional, Technical and Business Services',
    'Religion and Nonprofit',
    'Wholesale Trade',
  ],
  datasets: [{
    borderWidth: 0,
    data: [65, 101, 49, 12, 30, 120, 502, 94, 90, 59],
    backgroundColor: [
      '#121F34',
      '#1F365B',
      '#2A487A',
      '#345A98',
      '#3E6CB6',
      '#5882C6',
      '#7699D0',
      '#95AEDB',
      '#A4BBE0',
      '#C2D2EA',
    ],
    hoverBackgroundColor: [
      '#121F34',
      '#1F365B',
      '#2A487A',
      '#345A98',
      '#3E6CB6',
      '#5882C6',
      '#7699D0',
      '#95AEDB',
      '#A4BBE0',
      '#C2D2EA',
    ],
  }],
  type: 'pie',
};

const PieChart = () => (
  <Pie data={data} options={options} />
);

export default PieChart;
