// @flow
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Spin as Hamburger } from 'hamburger-react';

import Button from 'components/Button';
import CustomLink from 'components/Link';
import CustomHashLink from 'components/HashLink';
import { ReactComponent as Logo } from 'images/BHI logo-header.svg';
import { ReactComponent as LogoLight } from 'images/BHI logo-header-light.svg';
import { Wrapper, LinkWrapper, BurgerWrapper } from './StyledComponents';
import DropdownMenu from '../DropdownMenu';

type Props = {
  light?: boolean | string,
}

const NavBar = ({ light }: Props) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Wrapper light={light}>
        <Grid>
          <Row middle="xs" between="xs">
            <Col xs={6}>
              <Link to="/">
                { light ? <LogoLight /> : <Logo /> }
              </Link>
            </Col>
            <Col xs={12} md={6} className="hidden-xs hidden-sm">
              <LinkWrapper>
                <CustomLink
                  link="/about-us"
                  text="About Us"
                  light={light}
                  active={location.pathname.includes('/about-us')}
                />
                <CustomLink
                  link="/our-products"
                  text="Our Products"
                  light={light}
                  active={location.pathname.includes('/our-products')}
                />
                <CustomHashLink
                  link="/about-us#contact-us"
                  text="Contact Us"
                  light={light}
                />
                <a
                  style={{ justifySelf: 'end' }}
                  href="https://portal.bhidigital.com/auth/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button title="Sign In" link="link" />
                </a>
              </LinkWrapper>
            </Col>
            <Col xs={6} end="xs" className="hidden-md hidden-lg hidden-xl">
              <BurgerWrapper>
                <Hamburger
                  toggled={isOpen}
                  toggle={setIsOpen}
                  size={32}
                  color={light ? '#121F34' : '#FFFFFF'}
                />
              </BurgerWrapper>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
      <DropdownMenu open={isOpen} light={light} />
    </>
  );
};

export default NavBar;
