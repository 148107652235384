import styled, { css } from 'styled-components';

const Ul = styled.ul`
  z-index: 9999;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: fixed;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-120%)')};
  top: 96px;
  height: 100vh;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  
  li {
    padding-bottom: 64px;
    font-size: 24px;
    color: #FFFFFF;
  }

  ${({ light }) => light && css`
    background: ${({ theme }) => theme.colors.mist};
    
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  `}
`;

export {
  Ul,
};
