import styled, { css } from 'styled-components';
import { device } from 'constants/mediaQueries';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  height: 96px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99999;
  
  .container {
    width: 100%;
    @media ${device.mobileL} {
      width: 1184px;
    }
  }
  
  p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
  }
  
  svg {
    width: initial;
  }
  
  a:hover {
    color: #FFFFFF;
  }
  
  ${({ light }) => light && css`
    background: ${({ theme }) => theme.colors.mist};
    
    a {
      color: ${({ theme }) => theme.colors.primary};
    }

    a:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  `}
`;

const LinkWrapper = styled.div`
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
`;

const BurgerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  Wrapper,
  LinkWrapper,
  BurgerWrapper,
};
