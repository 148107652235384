import styled from 'styled-components';
import { device } from 'constants/mediaQueries';

const WhatWeDoSection = styled.div`
  padding-top: 164px;
  padding-bottom: 64px;

  @media ${device.mobileL} {
    padding-bottom: 0;
  }
  
  ul li {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    line-height: 24px;
    letter-spacing: ${({ theme }) => theme.font.letterSpacing};
    list-style: disc;
  }
  
  ul {
    margin-bottom: 30px;
    margin-left: 20px;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export {
  WhatWeDoSection,
};
